import React, { createContext, useState, FC, useContext } from "react";

const AppContext = createContext({auth:null});

export const AppContextProvider = ({children}: any) => {
    const [auth, setAuth] = useState(() => {
        const token = localStorage.getItem('token')
        if(token){
            return JSON.parse(token)
        }
        return null;
    })

    const values = {
        auth, 
        setAuth
    }

    return (
        <AppContext.Provider value={values}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContext;


export const useAppContext = () => useContext(AppContext);